// on NAVIGATE: IF you make any changes to supportOrgRelevance.ts, you MUST RECOMPILE & REPLACE supportOrgRelevance.js in Abfinder!!
// on ABFINDER: DO NOT make ANY changes to supportOrgRelevance.js - make changes in Navigate in supportOrgRelevance.ts, recompile & replace!!!

// COVERAGE_TYPES match the COVERAGE_TYPES in app/models/geographic_area.rb
type CoverageType =
  | 'All'
  | 'Provider Partner(s) Only'
  | 'State Region(s) Only'
  | 'City/Cities Only'

interface Rule {
  id?: number
  includes_virtual_only_providers?: boolean
  to_areas: Area[]
  from_areas: Area[]
  rule_text_en?: string
  rule_text_es?: string
  [key: string]: unknown // Allows for any additional keys without specifying them
}

interface Area {
  state: string
  coverage_type: CoverageType
  cities?: string[]
  regions?: string[]
  items?: number[] | string[]
  [key: string]: unknown // Allows for any additional keys without specifying them
}

export interface SupportOrg {
  name: string
  rules: Rule[]
  [key: string]: unknown // Allows for any additional keys without specifying them
}

export interface Params {
  fromState?: string
  toState?: string
  toRegion?: string
  toClinicId?: string
}

const fromState = (rule: Rule, params: Params) =>
  !!params.fromState &&
  rule.from_areas.some(
    (fromArea) =>
      fromArea.coverage_type === 'All' && fromArea.state === params.fromState
  )

const fromStateRegion = (rule: Rule, params: Params) =>
  !!params.fromState &&
  rule.from_areas.some(
    (fromArea) =>
      fromArea.coverage_type === 'State Region(s) Only' &&
      fromArea.state === params.fromState
  )

const fromNational = (rule: Rule) =>
  rule.from_areas.some((fromArea) => fromArea.state === 'National')

const toNational = (rule: Rule) =>
  rule.to_areas.some((toArea) => toArea.state === 'National')

const toState = (rule: Rule, params: Params) =>
  !!params.toState
    ? rule.to_areas.some(
        (toArea) =>
          toArea.coverage_type === 'All' && params.toState === toArea.state
      )
    : rule.to_areas.some(
        (toArea) =>
          toArea.coverage_type === 'All' && params.fromState === toArea.state
      )

const toStateRegion = (rule: Rule, params: Params) =>
  !!params.toState
    ? rule.to_areas.some(
        (toArea) =>
          toArea.coverage_type === 'State Region(s) Only' &&
          params.toState === toArea.state
      )
    : rule.to_areas.some(
        (toArea) =>
          toArea.coverage_type === 'State Region(s) Only' &&
          params.fromState === toArea.state
      )

const toMatchingClinicId = (rule: Rule, params: Params) =>
  !!params.toClinicId &&
  rule.to_areas.some(
    (toArea) =>
      toArea.coverage_type === 'Provider Partner(s) Only' &&
      (toArea.items || [])
        .map((x: string | number) => `${x}`)
        .includes(params.toClinicId!)
  )

const toClinicInState = (rule: Rule, params: Params) =>
  !!params.toState
    ? rule.to_areas.some(
        (toArea) =>
          toArea.coverage_type === 'Provider Partner(s) Only' &&
          toArea.state === params.toState
      )
    : rule.to_areas.some(
        (toArea) =>
          toArea.coverage_type === 'Provider Partner(s) Only' &&
          toArea.state === params.fromState
      )

const toAnySpecificStateRegion = (rule: Rule) =>
  rule.to_areas.some(
    (toArea) => toArea.coverage_type === 'State Region(s) Only'
  )

const toAnySpecificState = (rule: Rule) =>
  rule.to_areas.some((toArea) => toArea.coverage_type === 'All')

const toAnySpecificClinic = (rule: Rule) =>
  rule.to_areas.some(
    (toArea) => toArea.coverage_type === 'Provider Partner(s) Only'
  )

const traveling = (params: Params) =>
  !!params.fromState && !!params.toState && params.fromState !== params.toState

const searchToNotSpecified = (params: Params) =>
  !params.toState || params.toState === 'National'

// const loggerFunc = (rule, params) => {
// console.log(rule, params)
// return false
// }

type RuleTest = (rule: Rule, params: Params) => boolean
const ruleTestsInOrderMostRelevantFirst: RuleTest[] = [
  // loggerFunc,
  (r, p) => toMatchingClinicId(r, p) && fromState(r, p),
  (r, p) => toMatchingClinicId(r, p) && fromStateRegion(r, p),
  (r, p) => toMatchingClinicId(r, p) && fromNational(r), // col G
  (r, p) => toState(r, p) && fromState(r, p), // col H, L
  (r, p) => toState(r, p) && fromStateRegion(r, p), // col I, M
  (r, p) => toStateRegion(r, p) && fromState(r, p), // col J, N
  (r, p) => toStateRegion(r, p) && fromStateRegion(r, p), // col K, O
  (r, p) => traveling(p) && toClinicInState(r, p) && fromState(r, p), // col P
  (r, p) => traveling(p) && toClinicInState(r, p) && fromStateRegion(r, p), // col Q
  (r, p) => toNational(r) && fromState(r, p), // col R, col AE
  (r, p) => toNational(r) && fromStateRegion(r, p), // col S
  (r, p) => toState(r, p) && fromNational(r), // col T, col AG
  (r, p) => toStateRegion(r, p) && fromNational(r), // col U, col AF
  (r, p) => toClinicInState(r, p) && fromState(r, p), // col V (in same state, traveling will match in col P)
  (r, p) => toClinicInState(r, p) && fromStateRegion(r, p), // col W (in same state, traveling will match in col Q)
  (r, p) => toClinicInState(r, p) && fromNational(r), // col X, col AG
  (r, p) => toAnySpecificState(r) && fromState(r, p) && searchToNotSpecified(p), // col Y
  (r, p) =>
    toAnySpecificStateRegion(r) && fromState(r, p) && searchToNotSpecified(p), // col Z
  (r, p) =>
    toAnySpecificState(r) && fromStateRegion(r, p) && searchToNotSpecified(p), // col AA
  (r, p) =>
    toAnySpecificStateRegion(r) &&
    fromStateRegion(r, p) &&
    searchToNotSpecified(p), // col AB
  (r, p) =>
    toAnySpecificClinic(r) && fromState(r, p) && searchToNotSpecified(p), // col AC
  (r, p) =>
    toAnySpecificClinic(r) && fromStateRegion(r, p) && searchToNotSpecified(p), // col AD
  (r) => fromNational(r) && toNational(r), // col AH
  (r, p) => fromNational(r) && toAnySpecificState(r) && searchToNotSpecified(p), // col AI
  (r, p) =>
    fromNational(r) && toAnySpecificStateRegion(r) && searchToNotSpecified(p), // col AJ
  (r, p) =>
    fromNational(r) && toAnySpecificClinic(r) && searchToNotSpecified(p), // col AK
  // (r, p) => fromState(r, p), // col AL
  // (r, p) => fromStateRegion(r, p), // col AM
  // (r) => fromNational(r), // col AN
]

export const supportOrgRelevance = (
  supportOrg: SupportOrg,
  params: Params
): number => {
  const firstMatch = ruleTestsInOrderMostRelevantFirst.findIndex((ruleTest) =>
    supportOrg.rules.some((rule) => ruleTest(rule, params))
  )
  // findIndex returns -1 if no match is found, we return 999 in that case
  return firstMatch === -1 ? 999 : firstMatch
}
