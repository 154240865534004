/* eslint-disable no-use-before-define */

import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { setOf } from '../../utilities/custom_prop_types.js'
import { CurrentFilterFromServer } from './IndexFilters.js'

export const perPage = 50
const urls = {
  NavigatorClinic: '/navigator_clinics',
  NavigatorSupportOrg: '/navigator_support_orgs',
  Profile: '/profiles',
  NavigatorResourceRecord: '/navigator_resource_records',
}
export type RecordType = keyof typeof urls

export const useIndexStateSetup = (indexState: { currentPage: number }) => {
  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 200)
  }, [indexState.currentPage])
}

export const orderItemsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string,
    sortVal: PropTypes.func,
    directions: PropTypes.arrayOf(PropTypes.oneOf(['asc', 'desc'])),
    labels: PropTypes.objectOf(
      PropTypes.shape({ asc: PropTypes.string, desc: PropTypes.string })
    ),
  })
)

type SortValFuncReturningString = (id: number) => string
type SortValFuncReturningNumber = (id: number) => number
type SortValFunc = SortValFuncReturningString | SortValFuncReturningNumber
type ServerData = {
  ids: number[]
  distances: Record<number, number> | null
  location_query_lng_lat: [string, string] | null
  filter_errors: Record<string, string> | null
  current_filters: CurrentFilterFromServer[]
  page_title: string
  scope_to_us_states: string[]
}
// Define the type for the constructor parameters
export interface IndexStateConstructorParams {
  currentOrder?: string
  currentPage: number
  currentScope?: string
  defaultOrderSortValFunc?: SortValFunc
  errorMessage?: string | null
  filterErrors?: Record<string, string>
  filteredIds?: number[] | null
  filterDescriptions?: CurrentFilterFromServer[]
  hashOfRecords: Record<string, { id: number }>
  orderItems: Array<{
    key: string
    sortVal: SortValFunc
    directions: Array<'asc' | 'desc'>
    labels: Record<string, { asc: string; desc: string }>
  }>
  recordType: RecordType
  scopes: Record<string, { ids: number[] }>
  selectedIds?: Set<number> | null
  tieBreakerFunc?: SortValFunc
}

export class IndexState {
  currentOrder: string

  currentPage: number

  currentScope?: string

  defaultOrderSortValFunc?: SortValFunc

  filterDescriptions: CurrentFilterFromServer[]

  filteredIds: number[]

  filterErrors?: Record<string, string>

  hashOfRecords: Record<string, { id: number }>

  orderItems: IndexStateConstructorParams['orderItems']

  recordType: RecordType

  scopes: Record<string, { ids: number[] }>

  selectedIds: Set<number>

  errorMessage?: string | null

  tieBreakerFunc?: SortValFunc

  orderedScopedFilteredIds!: number[]

  paginatedOrderedScopedFilteredIds!: Set<number>

  constructor({
    currentOrder,
    currentPage,
    currentScope,
    defaultOrderSortValFunc,
    errorMessage = null,
    filterErrors = {},
    filteredIds = null,
    filterDescriptions = [],
    hashOfRecords,
    orderItems,
    recordType,
    scopes,
    selectedIds = null,
    tieBreakerFunc,
  }: IndexStateConstructorParams) {
    this.currentOrder = currentOrder || ''
    this.currentPage = currentPage
    this.currentScope = currentScope || ''
    this.defaultOrderSortValFunc = defaultOrderSortValFunc
    this.filterDescriptions = filterDescriptions
    this.filteredIds =
      filteredIds || Object.values(hashOfRecords).map((record) => record.id)
    this.filterErrors = filterErrors
    this.hashOfRecords = hashOfRecords
    this.orderItems = orderItems
    this.recordType = recordType
    this.scopes = scopes
    this.selectedIds = selectedIds || new Set()
    this.errorMessage = errorMessage
    this.tieBreakerFunc = tieBreakerFunc

    this.#recalculate()
  }

  static use(indexStateProps: IndexStateConstructorParams) {
    useEffect(() => {
      window.addEventListener('popstate', reloadPageOnPopState)

      return () => {
        window.removeEventListener('popstate', reloadPageOnPopState)
      }
    }, [])

    const [indexState, setIndexState] = useState(
      () => new this(indexStateProps)
    )

    return [indexState, setIndexState] as [IndexState, typeof setIndexState]
  }

  paginatedOrderedScopedFilteredRecords() {
    this.#validate()
    const ids = [...this.paginatedOrderedScopedFilteredIds.values()]

    return ids.map((id) => this.hashOfRecords[id])
  }

  totalRecords() {
    return this.orderedScopedFilteredIds.length
  }

  baseUrl() {
    return urls[this.recordType]
  }

  static setPage(pageNum: number) {
    return (oldState: IndexState) => {
      const newState = oldState._clone({ currentPage: pageNum })
      return newState
    }
  }

  static setOrder(newOrder: string) {
    return (oldState: IndexState) => {
      const newState = oldState._clone({
        currentPage: 1,
        currentOrder: newOrder,
      })
      return newState
    }
  }

  static setScope(newScope: string) {
    return (oldState: IndexState) => {
      const newState = oldState._clone({
        currentPage: 1,
        currentScope: newScope,
      })
      return newState
    }
  }

  static setSelectedIds(selectedIds: Set<number>) {
    return (oldState: IndexState) => oldState._clone({ selectedIds })
  }

  static setSelectedId(id: number, newVal: boolean) {
    return (oldState: IndexState) => {
      const newSelectedIds = new Set(oldState.selectedIds)
      if (newVal) newSelectedIds.add(id)
      else newSelectedIds.delete(id)
      return oldState._clone({
        selectedIds: newSelectedIds,
      })
    }
  }

  static setErrorMessage(message: string | null) {
    return (oldState: IndexState) => oldState._clone({ errorMessage: message })
  }

  static setOrderItems(orderItems: IndexStateConstructorParams['orderItems']) {
    return (oldState: IndexState) => oldState._clone({ orderItems })
  }

  static handleServerData(serverData: ServerData, isFirstRender = false) {
    return (oldState: IndexState) => {
      const newState = oldState._clone({
        filteredIds: serverData.ids.filter(
          (id: number) => oldState.hashOfRecords[id]
        ),
        filterDescriptions: serverData.current_filters,
        filterErrors: serverData.filter_errors || {},
        errorMessage: null,
      })

      if (!isFirstRender) {
        newState.currentPage = 1
        newState.#recalculate()
      }
      return newState
    }
  }

  _clone(overrides: Partial<IndexStateConstructorParams>) {
    return new (this.constructor as new (
      params: IndexStateConstructorParams
    ) => IndexState)({
      ...this,
      ...overrides,
    })
  }

  #recalculate() {
    this.#validate()

    const scopedFilteredIds =
      this.currentScope && this.scopes?.[this.currentScope]
        ? this.filteredIds.filter((id) =>
            this.scopes[this.currentScope!]!.ids.includes(id)
          )
        : this.filteredIds

    const orderedScopedFilteredIds = scopedFilteredIds
    sortByOrder(
      orderedScopedFilteredIds,
      this.currentOrder,
      this.orderItems,
      this.defaultOrderSortValFunc,
      this.tieBreakerFunc
    )

    const paginatedOrderedScopedFilteredIds = calculatePaginatedIds(
      this.currentPage,
      orderedScopedFilteredIds
    )

    const newSelectedIds = new Set(
      [...this.selectedIds].filter((id) =>
        paginatedOrderedScopedFilteredIds.has(id)
      )
    )

    this.orderedScopedFilteredIds = orderedScopedFilteredIds
    this.paginatedOrderedScopedFilteredIds = paginatedOrderedScopedFilteredIds
    this.selectedIds = newSelectedIds

    this.#validate()
  }

  #validate() {
    const INDEX_STATE_SHAPE = {
      currentOrder: PropTypes.string,
      currentPage: PropTypes.number.isRequired,
      defaultOrderSortValFunc: PropTypes.func,
      filterDescriptions: PropTypes.array.isRequired,
      filteredIds: PropTypes.arrayOf(PropTypes.number).isRequired,
      hashOfRecords: PropTypes.objectOf(
        PropTypes.shape({ id: PropTypes.number })
      ).isRequired,
      orderItems: orderItemsPropType.isRequired,
      recordType: PropTypes.oneOf(Object.keys(urls)),
      selectedIds: setOf(PropTypes.number),
      errorMessage: PropTypes.string,
      tieBreakerFunc: PropTypes.func,
    }

    PropTypes.checkPropTypes(INDEX_STATE_SHAPE, this, 'prop', 'IndexState')
  }
}

const calculatePaginatedIds = (
  page: number,
  orderedScopedFilteredIds: number[]
) => {
  const firstRecord = (page - 1) * perPage
  const lastRecord = firstRecord + perPage
  return new Set(orderedScopedFilteredIds.slice(firstRecord, lastRecord))
}

export const sortByOrder = (
  arrOfIds: number[],
  orderKeyWithDir: string | undefined,
  orderItems: IndexStateConstructorParams['orderItems'],
  defaultOrderSortValFunc?: SortValFunc,
  tieBreakerFunc?: SortValFunc
) => {
  if (!arrOfIds.length) return arrOfIds

  const [orderKey, orderDir] = (orderKeyWithDir || '').split(/_(?=[^_]+$)/)
  const orderItem = orderItems.find((item) => item.key === orderKey)
  const dirMultiplier = orderDir === 'desc' ? -1 : 1
  const orderItemsHaveArrived = !!orderItems?.length
  if (orderItemsHaveArrived && orderKey && !orderItem) {
    throw new Error(`Could not find orderItem with key ${orderKey}`)
  }
  const sortValFunc = orderItem ? orderItem.sortVal : defaultOrderSortValFunc
  if (sortValFunc) {
    const compare = (a: number, b: number, func: SortValFunc) => {
      if (!arrOfIds.length || !arrOfIds[0]) return 0
      const type = typeof func(arrOfIds[0])
      if (type === 'number') {
        // type is a number so func must be a SorvalFuncReturningNumber
        const numberFunc = func as unknown as SortValFuncReturningNumber
        return numberFunc(a) - numberFunc(b)
      }

      // type is a string so func must be a SorvalFuncReturningString
      const stringFunc = func as unknown as SortValFuncReturningString
      return stringFunc(a).localeCompare(stringFunc(b), undefined, {
        sensitivity: 'base',
      })
    }
    const sortFunc = (a: number, b: number) => {
      const initialCompare = compare(a, b, sortValFunc)
      if (initialCompare === 0 && !!tieBreakerFunc) {
        return compare(a, b, tieBreakerFunc)
      }
      return initialCompare
    }
    arrOfIds.sort((a, b) => sortFunc(a, b) * dirMultiplier)
  }
  return arrOfIds
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reloadPageOnPopState = (event: PopStateEvent | any) => {
  window.location.href = event.target.location
}
