import React, { FC, KeyboardEvent } from 'react'
import FilterDrawers from './FilterDrawers'
import CurrentFilters from './CurrentFilters'
import { TooltipAnchor, Tooltip } from '../Tooltip'
import { isBlank } from '../../utilities/utilities'

import './IndexFilters.scss'

// Define the types for filters
export type Filter = {
  label: string
  key: string
  InputComponent: React.ComponentType<{
    onChange: (newValue: string | string[]) => void
    onKeyDown?: (e: KeyboardEvent) => void
    label: string
    value: string | string[] | undefined
    [key: string]: unknown
  }>
  tooltipTextIfDisabled?: string
  extraProps?: Record<string, unknown>
}
export type CurrentFilterFromServer = {
  key: string
  label: string
  displayValue: string
}

// Define the props for the IndexFilters component
export interface IndexFiltersProps {
  applyFilters: (filters: Record<string, string | string[]>) => void
  currentFiltersFromServer: CurrentFilterFromServer[]
  drawerFilters?: Filter[]
  filterErrors?: Record<string, string>
  appliedFilters: Record<string, string | string[]>
  removeFilter: (filterKey: string) => void
  topFilters: Filter[]
  unappliedFilters: Record<string, string | string[]>
  setUnappliedFilters: React.Dispatch<
    React.SetStateAction<Record<string, string | string[]>>
  >
}

const IndexFilters: FC<IndexFiltersProps> = ({
  applyFilters,
  currentFiltersFromServer,
  drawerFilters,
  filterErrors,
  appliedFilters,
  removeFilter,
  topFilters,
  unappliedFilters,
  setUnappliedFilters,
}) => {
  const handleSearchOnKeyDownEnter = (e: KeyboardEvent) => {
    if (e.key !== 'Enter') return
    applyFilters(unappliedFilters)
    ;(e.target as HTMLElement).blur()
  }

  const topFilterError = filterErrors?.location
  const searchButtonDisabled = [appliedFilters, unappliedFilters].every(isBlank)

  return (
    <div id="IndexFilters">
      <div className="top-container">
        <div className="filters-and-submit">
          {topFilters.map(
            ({
              label,
              key,
              InputComponent,
              tooltipTextIfDisabled,
              extraProps,
            }) => (
              <TooltipAnchor
                key={key}
                placement="bottom"
                disabled={!tooltipTextIfDisabled || !extraProps?.disabled}
              >
                <InputComponent
                  key={key}
                  onChange={(newValue: string | string[]) =>
                    setUnappliedFilters({
                      ...unappliedFilters,
                      [key]: newValue,
                    })
                  }
                  onKeyDown={handleSearchOnKeyDownEnter}
                  label={label}
                  value={unappliedFilters[key]}
                  {...extraProps}
                />
                <Tooltip>{tooltipTextIfDisabled}</Tooltip>
              </TooltipAnchor>
            )
          )}
          <button
            disabled={searchButtonDisabled}
            onClick={() => applyFilters(unappliedFilters)}
            type="button"
          >
            Search
          </button>
        </div>
        {!!topFilterError && <div className="errors">{topFilterError}</div>}
      </div>
      {!!drawerFilters?.length && (
        <FilterDrawers
          appliedFilters={appliedFilters}
          applyFilters={applyFilters}
          drawers={drawerFilters}
        />
      )}
      <CurrentFilters
        removeFilter={removeFilter}
        currentFilters={currentFiltersFromServer}
        removeAllFilters={() => applyFilters({})}
        filterOrder={[...(topFilters || []), ...(drawerFilters || [])].map(
          (x) => x.key
        )}
      />
    </div>
  )
}

export default IndexFilters
