import { useEffect, useState } from 'react'
import { objectCompactBlank, objectExcept } from '../utilities/utilities'

// Type for Filters based on the example structure you provided
type Filters = Record<string, string | string[]>

// Hook definition
export const useIndexFilters = (
  initialFilters: Filters,
  handleApplyFilters: (
    newFilters: Filters,
    appliedFilters: Filters,
    isInitialLoad?: boolean
  ) => void
) => {
  const [appliedFilters, setAppliedFilters] = useState<Filters>(initialFilters)
  const [unappliedFilters, setUnappliedFilters] =
    useState<Filters>(initialFilters)

  const applyFilters = (unprocessedNewFilters: Filters) => {
    // Remove blank values from filters (empty arrays, empty strings, etc.)
    const newFilters = objectCompactBlank(unprocessedNewFilters)

    // Make the network call with new filters
    handleApplyFilters(newFilters, appliedFilters)
    setUnappliedFilters(newFilters)
    setAppliedFilters(newFilters)
  }

  // Apply initial filters on first load
  useEffect(() => {
    handleApplyFilters(initialFilters, initialFilters, true)
  }, [])

  const removeFilter = (keys: string | string[]) => {
    const updatedFilters = objectExcept(appliedFilters, keys)
    applyFilters(updatedFilters)
  }

  return {
    appliedFilters,
    applyFilters,
    removeFilter,
    setAppliedFilters,
    setUnappliedFilters,
    unappliedFilters,
  }
}
