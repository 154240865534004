import usStates from '../../utilities/us_states.ts'
import {
  OrgSelectInput,
  OrgArrayCheckboxInput,
  OrgArrayRadioInput,
  RADIO_ANY_VALUE,
} from './inputs/OrgInputs.jsx'
import { supportOrgRelevance } from '../../shared_navigate_ab_finder/supportOrgRelevance.ts'

const usStateOptions = Object.entries(usStates)
  .slice(1) // remove "National"
  .map((x) => x.reverse())

export const navigatorSupportOrgTopFilters = (
  providerOptions,
  statesWithNoActiveClinics,
  unappliedFilters
) => [
  {
    label: 'Abortion Seeker State',
    key: 'from_state_filter',
    InputComponent: OrgSelectInput,
    extraProps: {
      isClearable: true,
      options: usStateOptions,
    },
  },
  {
    label: 'Provider State (Optional)',
    key: 'to_state_filter',
    InputComponent: OrgSelectInput,
    tooltipTextIfDisabled:
      'To change provider state, please first select an abortion seeker state.',
    extraProps: {
      disabled: !unappliedFilters.from_state_filter,
      isClearable: true,
      isValueDisabled: (state) => statesWithNoActiveClinics.has(state), // disable states where there are no 'active' providers
      options: usStateOptions,
      placeholder: 'National',
    },
  },
  {
    label: 'Provider Name (Optional)',
    key: 'provider_filter',
    InputComponent: OrgSelectInput,
    tooltipTextIfDisabled:
      'To change provider name, please first select an abortion seeker state and a provider state.',
    extraProps: {
      isClearable: true,
      options: providerOptions
        .filter(({ states_served }) =>
          states_served.includes(unappliedFilters.to_state_filter)
        )
        .map(({ id, formatted_name }) => [formatted_name, id.toString()]),
      disabled:
        !unappliedFilters.to_state_filter || !unappliedFilters.to_state_filter,
    },
  },
]

export const orderItemsConstructor = ({ hashOfRecords, appliedFilters }) => [
  {
    directions: ['asc'],
    key: 'relevance',
    labels: {
      asc: { long: 'Relevance', short: 'Relevance' },
    },
    sortVal: (id) =>
      (appliedFilters.from_state_filter
        ? supportOrgRelevance(hashOfRecords[id], {
            fromState: appliedFilters.from_state_filter,
            toState: appliedFilters.to_state_filter,
            toClinicId: appliedFilters.provider_filter,
          })
        : 0) +
      // put all "not_allowed" orgs at the end
      (hashOfRecords[id].abortion_seeker_contact_preferences === 'not_allowed'
        ? 1000
        : 0),
  },
  {
    directions: ['asc', 'desc'],
    key: 'name',
    labels: {
      asc: { long: 'Name: A-Z', short: 'Name: A-Z' },
      desc: { long: 'Name: Z-A', short: 'Name: Z-A' },
    },
    sortVal: (id) => hashOfRecords[id].name,
  },
  {
    directions: ['asc', 'desc'],
    key: 'org_type',
    labels: {
      asc: { long: 'Type: A-Z', short: 'Type: A-Z' },
      desc: { long: 'Type: Z-A', short: 'Type: Z-A' },
    },
    sortVal: (id) => hashOfRecords[id].org_type,
  },
  {
    directions: ['asc', 'desc'],
    key: 'status',
    labels: {
      asc: { long: 'Status: A-Z', short: 'Status: A-Z' },
      desc: { long: 'Status: Z-A', short: 'Status: Z-A' },
    },
    sortVal: (id) => hashOfRecords[id].display_status,
  },
]

const orgStatusOptions = [
  {
    label: 'Any status',
    value: RADIO_ANY_VALUE,
  },
  {
    label: 'Active organizations',
    value: 'active',
  },
  {
    label: 'Paused organizations',
    value: 'paused',
  },
]

const orgTypeOptions = [
  {
    label: 'Any type',
    value: RADIO_ANY_VALUE,
  },
  {
    label: 'Funds only',
    value: 'Fund',
  },
  {
    label: 'PSOs only',
    value: 'PSO',
  },
  {
    label: 'Funds & PSOs only',
    value: 'Fund & PSO',
  },
]

const seekerContactOptions = [
  {
    label: 'Any contact',
    value: RADIO_ANY_VALUE,
  },
  {
    label: 'Works directly with abortion seekers',
    value: 'allowed',
  },
  {
    label: "Doesn't work directly with abortion seekers",
    value: 'not_allowed',
  },
]

export const filterDrawerConstructor = (filter_drawer_options, all_orgs) => [
  // label, URL query param, filter drawer component, props for drawer component
  {
    label: 'Support Type(s)',
    key: 'with_any_offerings',
    InputComponent: OrgArrayCheckboxInput,
    extraProps: { options: filter_drawer_options.with_any_offerings },
  },
  {
    label: 'Status',
    key: 'org_status',
    InputComponent: OrgArrayRadioInput,
    extraProps: { name: 'org_status', options: orgStatusOptions },
  },
  {
    label: 'Organization Type',
    key: 'org_type_eq',
    InputComponent: OrgArrayRadioInput,
    extraProps: { name: 'org_type_eq', options: orgTypeOptions },
  },
  {
    label: 'Abortion Seeker Contact',
    key: 'seeker_contact_filter',
    InputComponent: OrgArrayRadioInput,
    extraProps: {
      name: 'seeker_contact_filter',
      options: seekerContactOptions,
    },
  },
  {
    label: 'Filter by Name',
    key: 'name_eq_any',
    InputComponent: OrgSelectInput,
    extraProps: {
      options: Object.values(all_orgs)
        .map((org) => org.name)
        .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' })),
      isMulti: true,
    },
  },
]
